<template>
  <div :style="$vuetify.breakpoint.smAndDown ? 'padding: 15% 5%' : 'padding: 6% 5%'
    ">
    <PageBreadcrumbs :data="breadcrumbs" />
    <vs-row justify="center">
      <h1>Designer Bottoms</h1>
      <span class="material-symbols-outlined"> female </span>
    </vs-row>
    <Products :products="bottoms" />
  </div>
</template>

<script>
export default {
  name: 'Bottoms',
  components: {
    PageBreadcrumbs: () => import('../components/PageBreadcrumbs.vue'),
    Products: () => import('../components/Products.vue'),
  },
  data: () => ({
    breadcrumbs: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
      {
        text: 'Bottoms',
        disabled: true,
      },
    ],
  }),
  metaInfo() {
    return {
      meta: [
        {
        name: 'description',
        content: 'Discover Bengalina’s bottoms collection, offering both classic and modern styles. Our collection complements any top for a complete, stylish look.',
        },
        {
          property: 'og:title',
          content: 'Versatile Bottoms for Women | Bengalina Bangladesh',
        },
        {
          property: 'og:description',
          content: 'Complete your wardrobe with Bengalina’s bottoms collection, featuring stylish pants, skirts, and more for every occasion.',
        },
        {
          name: 'keywords',
          content: 'bottoms Dhaka, fashion bottoms Bangladesh, women’s pants Dhaka, stylish skirts Bangladesh, Bengalina bottoms collection, versatile bottoms Bangladesh',
        },
      ]
    }
  },
  computed: {
    bottoms() {
      return this.$store.state.bottoms;
    },
  },
};
</script>
